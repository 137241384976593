.sideNavStyle {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  background-color: rgba(255,255,255,0.95);
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
  box-shadow: 0 0 6px rgba(0, 0, 0, .5);
}

.ThemePickerOpenDrawerBtn {
  position: fixed;
  top: 5em;
  right: -1px;
  font-size: 1.5em;
  margin-left: 50px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9999999;
  padding: .23em .46em .23em .23em;
  box-shadow:-4px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 0.5em 0 0 0.5em;
  cursor: pointer;
  transition: 0.5s
}

.LinksStyle {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: table;
  transition: 0.3s
}
.LinksStyle:hover{
  background-color: rgba(0,0,0,0.10);
}
.Active{
  color:#fff;
  background-color: rgba(0,0,0,0.5);
}
.Active:hover{
  background-color: rgba(0,0,0,0.35);
}

.Open {
  width: 225px;
}

.OpenButton {
  right: 225px;
}
