.coupon #unycos-hero .unycos-hero {
  margin-bottom: 0;
}

.coupon #unycos-hero .unycos-hero .caption .title {
  line-height: 1.125em;
  max-width: 300px;
  margin: 0 auto;
}

.coupon #unycos-hero .unycos-hero-big-slider .unycos-hero-slide .thumb {
  opacity: .8;
}

.coupon .coupon-bar .coupon-bar-wrapper {
  /*max-height: 602px;*/
  height: auto;
  transition: height 1s cubic-bezier(0, 1, 0, 1);
}

.coupon .coupon-bar .coupon-bar-wrapper.confirm {
  /*max-height: 920px;*/
  transition: height 1s ease-in-out;
}

.coupon .unycos-features {
  margin: 6vmin 0;
}

.coupon .box-whatsapp {
  margin-bottom: 6vmin;
}

.coupon .box-whatsapp .btn {
  width: 100%;
  font-size: 1em;
}

@media (min-width: 550px) {
  .coupon #unycos-hero .unycos-hero-big-slider .unycos-hero-slide .thumb {
    top: 0;
  }

  .coupon .coupon-bar {
    margin-top: 4vmin;
    position: relative;
    z-index: 999;
  }

  .coupon #unycos-hero .unycos-hero .caption .title {
    transform: translateX(20px);
  }

  .coupon .coupon-bar .coupon-bar-wrapper {
    position: relative;
    overflow: hidden;
  }

  .coupon .box-whatsapp .btn {
    width: auto;
  }
}

@media (min-width: 1023px) {
  .coupon .coupon-bar {
    margin-top: -14vmin;
  }
}

@media (min-width: 1024px) {
  .coupon #unycos-hero .unycos-hero .caption .title {
    transform: translateX(-20px);
  }

  .coupon .coupon-bar {
    margin-top: -8vmin;
  }
}

@media (min-width: 1440px) {
  .coupon #unycos-hero .unycos-hero .caption .title {
    transform: translateX(-20px);
  }
}
