html {
  scroll-behavior: smooth;
}

.quiz-topbar {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  z-index: 99999;
}

.quiz-topbar-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (min-width: 1024px) {
  .quiz-topbar {
    position: absolute;
    padding: 0 48px;
  }
}
.quiz-landing{
  margin-top: 60px;
}
.quiz-landing #platform-hero {
  height: 96px;
}
.top_done.quiz-landing #platform-hero {
  height: 130px;
}
.quiz-landing #platform-hero h1{
  font-size: 1.5em;
}

.quiz-landing #platform-hero .row,
.quiz-landing .hero .container-fluid {
  height: 100%;
  align-items: center !important;
}

@media only screen and (min-width: 550px) {
  .quiz-landing #platform-hero {
    height:  230px;
  }

  .quiz-landing #platform-hero .row.middle-xs {
    height: 100%;
  }
  .quiz-landing #platform-hero h1{
    font-size: 1.75em;
  }
}

.quiz-app {
  margin: 20px 0;
}

.quiz-app-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
}

.question-step {
  background: #EDEFF4;
  height: 24px;
  width: 24px;
  padding: 0;
  border: 0;
  border-radius: 20px;
}

.question-step.is-visited {
  background-color: #98A7BC;
}

.question-step.is-active {
  background-color: #314E79;
}

.quiz-app-testemonials,
.quiz-app .timer {
  margin: 30px 0;
}

.quiz-app-testemonials {
  background-color: #D6DCE4;
  padding: 20px 20px 10px 20px;
  position: sticky;
  top: 40px;
  display: none;
}
.quiz-landing .quiz-app-testemonials .testimony {
  margin: 20px 40px;
}

.quiz-app-testemonials:before,
.quiz-app-testemonials:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #D6DCE4;
  width: 63px;
  z-index: 2;
  display: none;
}

.quiz-app .slick-dots li.slick-active {
  background-color: #fff;
}

.quiz-app-testemonials:before {
  left: 0;
}

.quiz-app-testemonials:after {
  right: 0;
}

.quiz-app .slick-prev,
.quiz-app .slick-next {
  display: none !important;
}

.quiz-app-calendar {
  padding-bottom: 80px;
}

.quiz-app-calendar-heading h2 {
  text-align: center;
  font-size: 1.25rem;
  margin:30px 0 0 0;
}

.quiz-app-calendar .button-select-first {
  font-size: 1em;
  top: -73px;
  left: calc(50% - 75px);
  width: 150px;
  position: absolute;
}

.quiz-sessionInfo {
  padding: 10px 0;
}

.quiz-sessionInfo li {
  display: inline-block;
  margin: 5px;
}
.quiz-sessionInfo li {display: block;text-align: center;}
.quiz-sessionInfo li:nth-child(2) {display: inline-block;}
.quiz-sessionInfo li:nth-child(3) {display: inline-block;}

.quiz-app .pricing-plans-tabs {
  padding: 0 !important;
  border: none;
}

.quiz-app .pricing-plans-tabs .platform-tabs-pane {
  padding: 15px;
}

.quiz-app .pricing-plans-tabs ul.platform-tabs {
  overflow: hidden;
  margin: 0;
  border: none;
}

.quiz-app .pricing-plans-tabs label[for="terms"],
.quiz-app .pricing-plans-tabs label[for="saveCard"] {
  display: flex;
}

.quiz-app .pricing-plans-tabs label[for="saveCard"]:before,
.quiz-app .pricing-plans-tabs label[for="terms"]:before {
  min-width: 24px;
}

.quiz-app .pricing-plans-tabs ul.platform-tabs  {
  overflow: hidden;
  margin: 0;
  border: none;
}

.quiz-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0px;
}
.row-buttons div{
  display: block;
}
.row-buttons .btn{
  display: block;
}

.quiz-success * {
  text-align: center;
}

.quiz-success img {
  margin-bottom: 20px;
}

.tel-form {
  position: relative;
}

.tel-form .input {
  text-align: left;
}

.tel-form .btn {
  position: absolute;
  right: 0;
}

.analyzing-phase {
  overflow: hidden;
  height: 36px;
}

.analyzing-phase span {
  display: block;
  text-align: center;
  padding: 6px 0;
  margin-bottom:5px;
  animation: changeText 6s ease 2s forwards;
}

@keyframes changeText {
  0% { transform: translateY(0); }
  20% { transform: translateY(-36px); -webkit-transform: translateY(-36px); -moz-transform: translateY(-36px); -ms-transform: translateY(-36px); -o-transform: translateY(-36px); }
  40% { transform: translateY(-36px); }
  60% { transform: translateY(-72px); }
  80% { transform: translateY(-72px); }
  100% { transform: translateY(-105px); }
}

@keyframes animateMsg {
  0% { opacity:0}
  50% { opacity:0.9}
  100% { opacity:0}
}

.animate-msg {
  opacity:0;
  animation: animateMsg 6s ease 0.1s forwards;
  -webkit-animation: animateMsg 6s ease 0.1s forwards;
}

.quiz-app .psyco:nth-child(1) {
  order: 1;
}

.quiz-app .psyco:nth-child(2) {
  order: 0;
}

.quiz-app .content-consultations.consultations {
  width: 100%;
  float: none;
  margin: 0 0 20px 0;
  padding: 0;
  display: inline-block;
}

.quiz-app .content-consultations.consultations .thumb img{
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

@media (min-width: 550px) {
  @keyframes changeText {
    0% { transform: translateY(0); }
    20% { transform: translateY(-40px); }
    40% { transform: translateY(-40px); }
    60% { transform: translateY(-80px); }
    80% { transform: translateY(-80px); }
    100% { transform: translateY(-116px); }
  }

  .quiz-sessionInfo li {
    display: inline-block;
    margin: 5px;
  }

  .quiz-app .psycos {
    margin: 80px 0 42px;
  }

  .quiz-success {
    flex-direction: row;
    align-items: self-start;
    margin-bottom: 0px;
  }
  .row-buttons div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-buttons .btn{
    margin:0 5px;
  }

  .quiz-success * {
    text-align: left;
  }

  .quiz-success img {
    margin-right: 20px;
    transform: translateY(12px);
  }
}

@media (min-width: 768px) {
  .quiz-app-testemonials {
    display: block;
    transform: translateY(-50px);
  }

  .quiz-app-steps {
    margin-top: 40px;
  }

  .quiz-app-calendar .button-select-first {
    font-size:1.25em;
    width: 200px;
    left:calc(50% - 100px);
  }

  .quiz-app-calendar {
    padding: 20px 0 80px;
  }

  .quiz-sessionInfo {
    padding: 0;
  }

  .quiz-sessionInfo li {
    margin: 10px;
  }

  .quiz-app-calendar,
  .quiz-app .timer:after {
    position: sticky;
  }

  .quiz-app-calendar {
    top: 0;
  }

  .quiz-app-calendar-heading h2 {
    text-align: left;
    font-size: 1.25rem;
    margin:10px 0 0 0;

  }

  .quiz-app .psyco .psy-card {
    box-shadow: 0px 5px 25px #CDD3E1;
  }

  .quiz-app .psyco:nth-child(1) {
    order: 0;
  }

  .quiz-app .psyco:nth-child(2) {
    order: 1;
  }

  .quiz-app .psyco:nth-child(3) {
    order: 2;
  }

  .quiz-app .pricing-plans-tabs .platform-tabs-pane {
    padding: 30px;
  }

  @keyframes changeText {
    0% { transform: translateY(0); }
    20% { transform: translateY(-44px); }
    40% { transform: translateY(-44px); }
    60% { transform: translateY(-85px); }
    80% { transform: translateY(-85px); }
    100% { transform: translateY(-128px); }
  }
}

@media (min-width: 1024px) {
  .quiz-app .timer {
    max-height: 660px;
    overflow: auto;
    position: relative;
    padding-top: 40px;
    padding-bottom: 0;
    transform: translateY(-61px);
    -webkit-transform: translateY(-61px);
    -moz-transform: translateY(-61px);
    -ms-transform: translateY(-61px);
    -o-transform: translateY(-61px);
}

  .quiz-app .timer:after {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(0deg, #EDEFF4 30%, transparent);
  }
}
