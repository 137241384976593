.Appointment {
  position: relative;
  display: block;
  border: 1px solid #BDC2C9;
  background: white;
  text-align: center;
  padding: 8px 6px;
  margin:2px;
  font-weight: 500;
  font-size: 0.95rem;
  border-radius:3px;
  &:hover{
    background: #BDC2C9;
    cursor: pointer;
  }
}

.Active{
  background: #17B893;
  color: white;
  border-color: #17B893;
  &:hover {
    background: #17B893;
    color: white;
    border-color: #17B893;
  }
}
