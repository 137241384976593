.box-subjects-list{
  padding:0 15px 15px 15px;
  h1{
    padding-left:30%;
    padding-right:30%;
  }
  .box-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .button-subject{
      margin:3px 4px;
      padding:10px;
      flex:1 1 auto;
      min-width:15%;
      &:hover{
        //background:$regent-grey;
        background:#BDC2C9;
        transform: scale(1);
      }
      &.is-active{
        //background: $lmem-secondary-color;
        //color:$white;
        background: #2CB3AA;
        border:1px solid #2CB3AA;
        color:#fff;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .box-subjects-list{
    h1{
      padding-left:10px;
      padding-right:10px;
      font-size: 2em;
    }
  }
}
@media only screen and (max-width: 450px) {
  .box-subjects-list{
    .box-list{
      display: block;
      .button-subject{
        display: block;
        margin:5px;
        padding:15px;
      }
    }
  }
}
